import React, { useContext, useEffect, useState } from "react";
// import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import ReactGA from "react-ga";
import { Switch } from "antd";

import UserContext from "./context/UserContext";
// import HomePage from "./pages/home";
// import GetSurveyResult from "./components/survey/getSurveyResult";
// import Spinner from "./components/spinner";
import AllRoutes from "./route";

// import { useUserAgent } from "./utils/detect";

const App = () => {
  const userContext = useContext(UserContext);
  const [changeColor, setChangeColor] = useState(false);
  const [changeFontSize, setChangeFontSize] = useState("");

  useEffect(() => {
    const userkeyid = localStorage.getItem("userkeyid");
    const crmuserid = localStorage.getItem("crmuserid");
    const sessionid = localStorage.getItem("sessionid");
    const customername = localStorage.getItem("customername");
    const customerphoto = localStorage.getItem("customerphoto");
    const isShowEvent = localStorage.getItem("isShowEvent");

    if (localStorage.getItem("shtk") === null) {
      localStorage.setItem("shtk", 0);
      userContext.updateUserState("shtk", 0);
    }
    const shtkItem = localStorage.getItem("shtk");
    userContext.updateUserState("shtk", shtkItem);

    //Auto Login...
    if (sessionid) {
      userContext.loginUserSuccess(
        userkeyid,
        crmuserid,
        sessionid,
        customername,
        customerphoto,
        isShowEvent
      );
    }

    ReactGA.initialize("G-8PJ5ZV8BRN");
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const onChange = (pCheck) => {
    setChangeColor(pCheck);
    userContext.updateUserState("isDarkMode", !userContext.state.isDarkMode);
  };

  const onChangeFontSize = (pFontName) => {
    setChangeFontSize(pFontName);
  };

  return (
    <div
      className={`flex flex-col items-center min-h-screen font-roboto ${
        changeColor ? "dark bg-gender-main_dark_bg_color" : "bg-gray-100"
      } ${changeFontSize && changeFontSize}`}
    >
      <div className="hidden w-full sm:flex justify-center items-center gap-2.5 bg-white dark:bg-gender-main_dark_bg_color py-2.5">
        <div className="flex gap-2.5">
          <div
            onClick={() => onChangeFontSize("")}
            className="flex w-7 h-7 cursor-pointer rounded-[5px] bg-gen-main_red_color text-white hover:bg-gen-main_red_color/80 justify-center items-center"
          >
            <span className="flex items-center justify-center text-center p-1 text-xs font-semibold">
              A
            </span>
          </div>
          <div
            onClick={() => onChangeFontSize("medium-text-size")}
            className="flex w-7 h-7 cursor-pointer rounded-[5px] bg-gen-main_red_color text-white hover:bg-gen-main_red_color/80 justify-center items-center"
          >
            <span className="flex items-center justify-center text-center p-1 text-sm font-semibold">
              A
            </span>
          </div>
          <div
            onClick={() => onChangeFontSize("large-text-size")}
            className="flex w-7 h-7 cursor-pointer rounded-[5px] bg-gen-main_red_color text-white hover:bg-gen-main_red_color/80 justify-center items-center"
          >
            <span className="flex items-center justify-center text-center p-1 text-base font-semibold">
              A
            </span>
          </div>
        </div>
        <span className="dark:text-white text-sm">Дэвсгэр өнгө:</span>

        <Switch
          onChange={onChange}
          defaultChecked={changeColor}
          checked={changeColor}
          checkedChildren={<i className="fa-regular fa-moon" />}
          unCheckedChildren={<i className="fa-solid fa-sun-bright" />}
        />
      </div>

      <AllRoutes />

      {/* <Router>
        <Suspense
          fallback={
            <div className="flex justify-center items-center mt-[250px]">
              <Spinner />
            </div>
          }
        >         
          <Routes>
            <Route path="/surveyResult" element={<GetSurveyResult />} />
            <Route path="/" element={<HomePage path={"/"} />} />
            <Route
              path="/profile/:id"
              element={<HomePage path={"/profile/:id"} />}
            />
            <Route path="/news" element={<HomePage path={"/news"} />} />
            <Route path="/news/:id" element={<HomePage path={"/news/:id"} />} />
            <Route
              path="/discussion"
              element={<HomePage path={"/discussion"} />}
            />
            <Route path="/about" element={<HomePage path={"/about"} />} />
            <Route
              path="/search/:searchText"
              element={<HomePage path={"/search/:searchText"} />}
            />

            <Route path="/legal" element={<HomePage path={"/legal"} />} />

            <Route path="/partners" element={<HomePage path={"/partners"} />} />
            <Route
              path="/notification"
              element={<HomePage path={"/notification"} />}
            />
            <Route
              path="/discussion/:id"
              element={<HomePage path={"/discussion/:id"} />}
            />
            <Route path="/groups" element={<HomePage path={"/groups"} />} />
            <Route
              path="/groups/:id"
              element={<HomePage path={"/groups/:id"} />}
            />
            <Route
              path="/trainings"
              element={<HomePage path={"/trainings"} />}
            />
            <Route
              path="/trainings/:id"
              element={<HomePage path={"/trainings/:id"} />}
            />
            <Route path="/events" element={<HomePage path={"/events"} />} />
            <Route
              path="/events/:id"
              element={<HomePage path={"/events/:id"} />}
            />
            <Route path="/survey" element={<HomePage path={"/survey"} />} />
            <Route
              path="/survey/:id"
              element={<HomePage path={"/survey/:id"} />}
            />
            <Route
              path="/survey/:id/show"
              element={<HomePage path={"/survey/:id/show"} />}
            />
            <Route
              path="/training/:id/exam"
              element={<HomePage path={"/training/:id/exam"} />}
            />
            <Route path="/help/:id" element={<HomePage path={"/help/:id"} />} />

            <Route path="/promise" element={<HomePage path={"/promise"} />} />
          </Routes>
        </Suspense>
      </Router> */}
    </div>
  );
};

export default App;
