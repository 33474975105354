import React, { useState } from "react";

import { loginService } from "../utils";

const UserContext = React.createContext();

const initialState = {
  saving: false,
  logginIn: false,
  error: null,
  errorCode: null,
  userkeyid: null,
  crmuserid: null,
  sessionid: null,
  customername: null,
  customerphoto: null,
  isShowEvent: false,
  shtk: 0,
  isFull: "0",
  isDarkMode: false,
};

export const UserStore = (props) => {
  const [state, setState] = useState(initialState);
  const [badWords, setBadWords] = useState([]);

  const logout = () => {
    localStorage.removeItem("userkeyid");
    localStorage.removeItem("crmuserid");
    localStorage.removeItem("sessionid");
    localStorage.removeItem("customername");
    localStorage.removeItem("customerphoto");
    localStorage.removeItem("isShowEvent");
    setState(initialState);
  };

  const loginUser = async (pUsername, pPassword) => {
    // console.log("login_pUsername :", pUsername);
    // console.log("login_pPassword :", pPassword);

    await loginService({
      username: pUsername,
      password: pPassword,
      isHash: "1",
    })
      .then((response) => {
        // console.log("login_response :", response);
        if (response.data.response.status === "success") {
          const result = response.data.response.result;
          const userkeyid = result.userkeyid;
          const crmuserid = result.crmuserid;
          const sessionid = result.sessionid;
          const customername = result.customername;
          const customerphoto = result.customerphoto;

          loginUserSuccess(
            userkeyid,
            crmuserid,
            sessionid,
            customername,
            customerphoto,
            true,
            1
          );
        } else {
          setState({
            ...state,
            logginIn: false,
            error: response.data.response.text,
            errorCode: response.data.response.code,
            userkeyid: null,
            crmuserid: null,
            sessionid: null,
            customername: null,
            customerphoto: null,
          });
        }
      })
      .catch((error) => {
        setState({
          ...state,
          logginIn: false,
          error: error.message,
          errorCode: error.code,
          userkeyid: null,
          crmuserid: null,
          sessionid: null,
          customername: null,
          customerphoto: null,
        });
      });
  };

  const loginUserSuccess = (
    pUserkeyid,
    pCrmUserId,
    pSessionId,
    pCustomerName,
    pCustomerPhoto,
    pIsShowEvent,
    pShowShtork
  ) => {
    localStorage.setItem("userkeyid", pUserkeyid);
    localStorage.setItem("crmuserid", pCrmUserId);
    localStorage.setItem("sessionid", pSessionId);
    localStorage.setItem("customername", pCustomerName);
    localStorage.setItem("customerphoto", pCustomerPhoto);
    localStorage.setItem("isShowEvent", pIsShowEvent);
    localStorage.setItem("shtk", pShowShtork);

    setState({
      ...state,
      logginIn: false,
      error: null,
      errorCode: null,
      userkeyid: pUserkeyid,
      crmuserid: pCrmUserId,
      sessionid: pSessionId,
      customername: pCustomerName,
      customerphoto: pCustomerPhoto,
      isShowEvent: pIsShowEvent,
      shtk: pShowShtork,
    });
  };

  const updateState = (pName, pValue) => {
    setState((prev) => ({ ...prev, [pName]: pValue }));
  };

  const updateUserState = (pName, pUpdateField) => {
    updateState(pName, pUpdateField);
  };

  const onShowedEventInfo = () => {
    localStorage.setItem("isShowEvent", false);
    updateState("isShowEvent", false);
  };

  const onSetBadWords = (pData) => {
    setBadWords(pData);
  };

  return (
    <UserContext.Provider
      value={{
        state,
        loginUser,
        loginUserSuccess,
        logout,
        onShowedEventInfo,
        updateUserState,
        onSetBadWords,
        badWords,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
