import React, { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import Spinner from "./components/spinner/index.js";

const HomePage = React.lazy(() => {
  return import("./pages/home/index.js");
});

const SurveyResultPage = React.lazy(() => {
  return import("./components/survey/getSurveyResult.js");
});

const routes = [
  {
    path: "/",
    component: <HomePage path={"/"} />,
    exact: true,
  },
  {
    path: "/surveyResult",
    component: <SurveyResultPage />,
    exact: false,
  },
  {
    path: "/profile/:id",
    component: <HomePage path={"/profile/:id"} />,
    exact: false,
  },
  {
    path: "/news",
    component: <HomePage path={"/news"} />,
    exact: false,
  },
  {
    path: "/news/:id",
    component: <HomePage path={"/news/:id"} />,
    exact: false,
  },
  {
    path: "/discussion",
    component: <HomePage path={"/discussion"} />,
    exact: false,
  },
  {
    path: "/discussion/:id",
    component: <HomePage path={"/discussion/:id"} />,
    exact: false,
  },
  {
    path: "/about",
    component: <HomePage path={"/about"} />,
    exact: false,
  },
  {
    path: "/search/:searchText",
    component: <HomePage path={"/search/:searchText"} />,
    exact: false,
  },
  {
    path: "/legal",
    component: <HomePage path={"/legal"} />,
    exact: false,
  },
  {
    path: "/suggestions",
    component: <HomePage path={"/suggestions"} />,
    exact: false,
  },
  {
    path: "/partners",
    component: <HomePage path={"/partners"} />,
    exact: false,
  },
  {
    path: "/notification",
    component: <HomePage path={"/notification"} />,
    exact: false,
  },
  {
    path: "/groups",
    component: <HomePage path={"/groups"} />,
    exact: false,
  },
  {
    path: "/groups/:id",
    component: <HomePage path={"/groups/:id"} />,
    exact: false,
  },
  {
    path: "/trainings",
    component: <HomePage path={"/trainings"} />,
    exact: false,
  },
  {
    path: "/trainings/:id",
    component: <HomePage path={"/trainings/:id"} />,
    exact: false,
  },
  {
    path: "/training/:id/exam",
    component: <HomePage path={"/training/:id/exam"} />,
    exact: false,
  },
  {
    path: "/events",
    component: <HomePage path={"/events"} />,
    exact: false,
  },
  {
    path: "/events/:id",
    component: <HomePage path={"/events/:id"} />,
    exact: false,
  },
  {
    path: "/survey",
    component: <HomePage path={"/survey"} />,
    exact: false,
  },
  {
    path: "/survey/:id",
    component: <HomePage path={"/survey/:id"} />,
    exact: false,
  },
  {
    path: "/survey/:id/show",
    component: <HomePage path={"/survey/:id/show"} />,
    exact: false,
  },
  {
    path: "/help/:id",
    component: <HomePage path={"/help/:id"} />,
    exact: false,
  },
  {
    path: "/promise",
    component: <HomePage path={"/promise"} />,
    exact: false,
  },
];

export default function AllRoutes() {
  return (
    <Router>
      <Suspense
        fallback={
          <div className="flex justify-center items-center mt-[250px]">
            <Spinner />
          </div>
        }
      >
        <Routes>
          {routes.map((route, index) => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              element={route.component}
            />
            // <RouteMiddleware
            //   key={index}
            //   path={route.path}
            //   title={route.path}
            //   component={route.component}
            //   exact={route.exact}
            // />
          ))}
        </Routes>
      </Suspense>
    </Router>
  );
}
