import axios from "axios";
import { notification } from "antd";

import pako from "./gzip";
// export const runService = async (pCommand, pParameters) => {
//   let axiosConfig = {
//     headers: {
//       "Content-Type": "application/json;charset=UTF-8",
//     },
//   };

//   let config = {
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };
//   var sessionId = localStorage.getItem("sessionid")
//     ? localStorage.getItem("sessionid")
//     : "65178215-7896-4513-8e26-896df9cb36ad";

//   let bodys = { ignorePermission: "1", ...pParameters };

//   let requestBody = ecomZ({
//     request: {
//       command: pCommand,
//       sessionid: sessionId,
//       zip: "0",
//       returnByStandartJson: "1",
//       parameters: bodys,
//     },
//   });
//   // console.log("WS_COMPRESSED_REQUEST: ", JSON.stringify(requestBody));

//   var myHeaders = new Headers();
//   myHeaders.append("Content-Type", "application/json");

//   var requestOptions = {
//     method: "POST",
//     headers: myHeaders,
//     body: requestBody,
//   };

//   const response = await fetch(
//     "https://backend-gender.interactive.mn:8181/erp-services/RestWS/runJsonz",
//     requestOptions
//   )
//     .then((response) => response.json())
//     .then((result) => console.log("fetch_response: ", result))
//     .catch((error) => console.log("error", error));

//   // const response = await axios.post(
//   //   process.env.REACT_APP_SERVER_URL,
//   //   "H4sIAAAAAAAAAy2OwQ6CMBBE/6VnSSjQUjgaPWg0ITHRI6mwkh7a4rYckPjvLsQ97WZm38zCEN4ThMjqhXXeWu16VrPm0l4P99Px0aZpwXYsQAjGO7NqUvBSZVwkpapkUgieJwoymdDVv6rumUvd08vHjGROaUOIE7r9fIsE1xjPwTuSOEmjRm0hAoY13wzOIzSA1mxxf1OYQwRLNj2gn8atBJciz1SaF0qootpIg3EDUb40P0c3cdjWAAAA",
//   //   axiosConfig
//   // );

//   console.log("WS_RESPONSE: ", response);

//   if (response.data.response.code === "session_unregistred") {
//     localStorage.removeItem("sessionid");
//     localStorage.removeItem("userkeyid");
//     localStorage.removeItem("crmuserid");
//     localStorage.removeItem("customername");
//     localStorage.removeItem("customerphoto");
//   }

//   if (response.data.response.status === "error") {
//     throw new Error(response.data.response.text);
//   }

//   return response;
// };

// export const loginService = async (pParameters) => {
//   const response = await axios.post(process.env.REACT_APP_SERVER_URL, {
//     request: {
//       command: "login",
//       parameters: pParameters,
//       isCustomer: "1",
//     },
//   });

//   if (response.data.response.status === "success") {
//     axios
//       .post(process.env.REACT_APP_SERVER_URL, {
//         request: {
//           command: "connectClient",
//           parameters: {
//             userKeyId: response.data.response.result.userkeyid,
//           },
//           isCustomer: "1",
//           sessionId: response.data.response.result.sessionid,
//         },
//       })
//       .catch((err) => showErrorMessage("Алдаа", err.message));
//   }
//   return response;
// };

export const runService = async (pCommand, pParameters) => {
  var sessionId = localStorage.getItem("sessionid")
    ? localStorage.getItem("sessionid")
    : "65178215-7896-4513-8e26-896df9cb36ad";

  let bodys = { ignorePermission: "1", ...pParameters };
  let requestBody = ecomZ({
    request: {
      command: pCommand,
      sessionid: sessionId,
      zip: "1",
      returnByStandartJson: "1",
      parameters: bodys,
    },
  });

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json;charset=UTF-8");

  // const httpsAgent = new https.Agent({
  //   rejectUnauthorized: false,
  // });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: requestBody,
    // agent: httpsAgent,
  };

  const res = await fetch(process.env.REACT_APP_SERVER_URL, requestOptions);
  const tmpResponse1 = await res.text();
  const tmpResponse = decomZ(tmpResponse1);

  const response = {
    data: {
      ...tmpResponse,
    },
  };

  if (response.data.response.code === "session_unregistred") {
    localStorage.removeItem("sessionid");
    localStorage.removeItem("userkeyid");
    localStorage.removeItem("crmuserid");
    localStorage.removeItem("customername");
    localStorage.removeItem("customerphoto");

    window.location.reload();
  }

  if (response.data.response.status === "error") {
    throw new Error(response.data.response.text);
  }

  return response;
};

export const loginService = async (pParameters) => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json;charset=UTF-8");

  let requestBody = ecomZ({
    request: {
      command: "login",
      parameters: pParameters,
      isCustomer: "1",
    },
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: requestBody,
  };

  let res = await fetch(process.env.REACT_APP_SERVER_URL, requestOptions);
  let tmpResponse = await res.json();

  let response = {
    data: {
      ...tmpResponse,
    },
  };

  if (response.data.response.status === "success") {
    requestBody = ecomZ({
      request: {
        command: "connectClient",
        parameters: {
          userKeyId: response.data.response.result.userkeyid,
        },
        isCustomer: "1",
        sessionId: response.data.response.result.sessionid,
      },
    });

    res = await fetch(process.env.REACT_APP_SERVER_URL, requestOptions);
    tmpResponse = await res.json();

    response = {
      data: {
        ...tmpResponse,
      },
    };
  }
  return response;
};

export const getListData = async (
  pMetaDataId,
  pBody,
  pOffset,
  pPageSize,
  pSort,
  pCriteria,
  pPaging = false
) => {
  var bodys = {
    systemmetagroupid: pMetaDataId,
    ignorePermission: "1",
    ...pBody,
  };
  var paging = {
    paging: {
      offset: pOffset,
      pagesize: pPageSize,
      sortcolumnnames: pSort,
    },
  };
  var criteria = { criteria: pCriteria };

  var returnVal = [];
  var response = await runService("PL_MDVIEW_004", {
    ...bodys,
    ...paging,
    ...criteria,
  });

  if (response.data.response.status === "error") {
    showWarningMessage("Амжилтгүй", response.data.response.text);
  } else {
    if (pPaging) {
      returnVal = response.data.response;
    } else {
      returnVal = response.data.response.result;
    }
  }
  return returnVal;
};

export const generateGuidFromText = (text) => {
  let guid = "";

  for (let i = 0; i < 20; i++) {
    const charCode = text.charCodeAt(i).toString(16);
    guid += charCode;
  }

  return guid;
};

export const uploadFile = async (pFile) => {
  const guidName = generateGuidFromText(pFile.name);
  const dotIndex = pFile.name.lastIndexOf(".");

  if (dotIndex === -1) {
    return "";
  }

  const fileExtension = pFile.name.substring(dotIndex + 1);
  const timestamp = Date.now();
  const fileNewName = `file_${timestamp}_${guidName}.${fileExtension}`;

  var formData = new FormData();
  formData.append(fileNewName, pFile);
  formData.append("sessionId", "65178215-7896-4513-8e26-896df9cb36ad");

  try {
    const response = await axios({
      method: "POST",
      url: process.env.REACT_APP_FILE_UPLOAD_URL,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });

    if (response.data.response.status === "success") {
      return response.data.response.files.substring(
        response.data.response.files.indexOf("storage")
      );
    }

    return null;
  } catch (error) {
    showErrorMessage("Алдааs", console.log(error.message));
    return null;
  }
};

export const showSuccessMessage = (pTitle, pMessage) => {
  notification["success"]({
    message: pTitle,
    description: pMessage,
    maxCount: 3,
    style: {
      color: "rgba(0, 0, 0, 0.65)",
      border: "1px solid #b7eb8f",
      backgroundColor: "#f6ffed",
    },
    duration: 4,
  });
};

export const showWarningMessage = (pTitle, pMessage) => {
  notification["warning"]({
    message: pTitle,
    description: pMessage,
    maxCount: 3,
    style: {
      color: "rgba(0, 0, 0, 0.65)",
      border: "1px solid #ffe58f",
      backgroundColor: "#fffbe6",
    },
    duration: 4,
  });
};

export const showErrorMessage = (pTitle, pMessage) => {
  notification["error"]({
    message: pTitle,
    description: pMessage,
    maxCount: 3,
    style: {
      color: "rgba(0, 0, 0, 0.65)",
      border: "1px solid #ffa39e",
      backgroundColor: "#fff1f0",
    },
    duration: 4,
  });
};

export const showInfoMessage = (pTitle, pMessage) => {
  notification["info"]({
    message: pTitle,
    description: pMessage,
    maxCount: 3,
    style: {
      color: "rgba(0, 0, 0, 0.65)",
      border: "1px solid #91d5ff",
      backgroundColor: "#e6f7ff",
    },
    duration: 4,
  });
};

export const isNull = (pValue) => {
  if (pValue === undefined || pValue === null) {
    return true;
  }

  return false;
};

export const isEmpty = (pValue) => {
  if (isNull(pValue)) {
    return true;
  }

  let stringValue = parseToString(pValue, "");
  return stringValue.trim() === "";
};

export const isEmptyArray = (pValue) => {
  if (isNull(pValue)) {
    return true;
  }

  if (!Array.isArray(pValue)) {
    return true;
  }

  return pValue.length === 0;
};

export const parseToBoolean = (pValue, pDefaultValue) => {
  // console.log("parseToBoolean_pValue :", pValue);
  if (isNull(pValue)) {
    return pDefaultValue;
  }

  if (typeof pValue === "boolean") {
    return pValue;
  }

  let intValue = parseToInteger(pValue);
  if (isNull(intValue)) {
    return pDefaultValue;
  } else {
    return intValue === 1;
  }
};

export const parseToInteger = (pValue, pDefaultValue) => {
  if (isNull(pValue)) {
    return pDefaultValue;
  }

  if (Number.isInteger(pValue)) {
    return pValue;
  }

  let stringValue = pValue.toString().toLowerCase();
  switch (stringValue) {
    case "true":
      return 1;
    case "false":
      return 0;
    case "1":
      return 1;
    default:
      return 0;
  }
};

export const parseToString = (pValue, pDefaultValue) => {
  return isNull(pValue) ? pDefaultValue : pValue.toString();
};

export const cleanBadWords = (pValue, bad_words) => {
  const textArray = pValue.split(" ");
  const filteredText = textArray.map((word) => {
    if (!isEmptyArray(bad_words.filter((badWord) => badWord.word === word))) {
      var str = new Array(word.length + 1).join("*");
      return str;
    }
    return word;
  });
  return filteredText.join(" ");
};

export const converPdfBlob = async (serviceName) => {
  var config = {
    method: "get",
    url: serviceName,
    headers: {},
  };

  const response = await axios(config).catch(function (error) {
    console.log(error);
  });

  return response?.data;
};
export const getLocation = async () => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: "https://ipapi.co/json/",
    headers: {},
  };
  const response = await axios(config).catch(function (error) {
    console.log(error);
  });

  return response?.data;
};

export const getMimeType = (pValue) => {
  const regularExpression = /(?:\.([^.]+))?$/;

  return regularExpression.exec(pValue)[1];
};

export const decomZ = (b64Data) => {
  var strData = atob(b64Data);
  var charData = strData.split("").map(function (x) {
    return x.charCodeAt(0);
  });
  var binData = new Uint16Array(charData);
  var data = pako.inflate(binData);
  try {
    strData = new Uint8Array(data).reduce(function (data, byte) {
      return data + String.fromCharCode(byte);
    }, "");
  } catch (ex) {}
  var result = JSON.parse(decodeURIComponent(escape(strData)));
  return result;
};

export const ecomZ = (json) => {
  var encoded = unescape(encodeURIComponent(JSON.stringify(json)));
  var charData = encoded.split("").map(function (x) {
    return x.charCodeAt(0);
  });

  // var binData = new Uint16Array(charData);
  var zippedResult = pako.gzip(charData, { to: "string" });
  var ss = btoa(zippedResult);
  return ss;
};

// Session -д утга хадгалах
export const createSession = (pKey, pValue) => {
  sessionStorage.setItem(
    btoa(pKey),
    btoa(unescape(encodeURIComponent(JSON.stringify(pValue))))
  );
};

// Session -с утга авах
export const getSession = (pKey) => {
  var sessionItem = sessionStorage.getItem(btoa(pKey));

  if (!isNull(sessionItem)) {
    return JSON.parse(decodeURIComponent(escape(atob(sessionItem))));
  }

  return null;
};
