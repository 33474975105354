import React from "react";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function Spinner() {
  return (
    <div className="flex items-center justify-center">
      <Spin
        size="large"
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 60,
              color: "red",
            }}
            spin
          />
        }
      />
    </div>
  );
}
